<template>
  <div class="ml-5 mr-5">
    <div class="text-right">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      ></loading>
    </div>
        <!-- v-data-table  -->
  <v-row>
    <v-col cols="3">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            label="Search by Month & Year"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          type="month"
          no-title
          scrollable
          :max="max"
          :min="min"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu.save(date)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="5">
      <v-text-field
            v-model="panelist_ids"
            label="Enter Panelist Ids Comma Seperated"
          ></v-text-field>
    </v-col>
    <v-col cols="1">
      <v-btn
            small
            color="primary"
            class="mt-5"
            @click="getReport()"
          >
            Filter
          </v-btn>
    </v-col>
    <v-col cols="1">
      <v-btn
            small
            color="primary"
            @click="reset()"
            class="mt-5"
          >
            Reset
          </v-btn>
    </v-col>
    </v-row>
     <v-row >
       <v-col cols="11" >
       </v-col>
    <v-col cols="1">
      <v-btn
            small
            color="primary"
            @click="exportReport()"
          >
            Export
          </v-btn>
          <download-csv
              :data = "exportData" id="exportButton" :name="csv_name" style="display:none;">
              Download Data
          </download-csv>
    </v-col>
  </v-row>
    <v-row>
      <v-col cols="12">
          <div class="tableWrapper">
            <v-data-table
              :headers="headers"
              item-key="product_id"
              :page.sync="page"
              :items="affinity_data"
              :options.sync="options"
              :server-items-length="totalAffinity"
              :loading="isLoading"
              class="elevation-1"
              hide-default-footer
              @page-count="pageCount = $event"
            >
              <template v-slot:item.actions="{ item }">
                <v-icon
                  medium
                  color="grey"
                  v-show="checkIfOperationExistForModule('edit')"
                  @click="addToUpdate($event, item.affinity_id)"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <template v-slot:item.dates="{ item }">
                <p>{{date}}</p>
              </template>
            </v-data-table>
            <div class="text-center pt-4 pb-4">
              <Pagination
            :isloading="isLoading"
            :startRecord="startRecord"
            :currentPage="currentPage"
            :lastPage="lastPage"
            :lastRecord="lastRecord"
            :totRecords="totRecords"
            :isCurrentPageClass="isCurrentPageClass"
            :perpage="perpage"
            :getLastPageClass="getLastPageClass"
            :totPage="totPage"
            :getPages="getPages"
            @handlePerPage="handlePerPage"
            @paginate="paginate"
            @last="last"
            @getDataByPage="getDataByPage"
            :showPerPage="showPerPage"
            />
            </div>

            <div></div>
          </div>
      </v-col>
    </v-row>
    <!-- v-data-table-ends -->
  </div>
</template>

<script>
import axios from "axios";
import Index from "./Index.vue";
import Buttons from "./component/Buttons.vue";
import Item from "../../components/base/Item.vue";
import Pagination from "./component/Pagination.vue";
import moment from 'moment'
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import JsonCSV from 'vue-json-csv';

export default {
  components: { Index, Buttons, Loading, Pagination, 'downloadCsv': JsonCSV },
  name: 'PanelistReport',
  data() {
    return {
      toggleUpdateModal:false,
      //////table data starts here////
      selected: [],
      options: {},
      sort: "",
      affinity_data: [],
      totalAffinity: 0,
      editReportMode: false,
      fromDateMenu: false,
      fromDateVal: null,
      //  minDate: "2020-01-05",
      //  maxDate: "2019-08-30",
      headers: [
        {
          text: "Panelist ID",
          value: "competi_id",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Email Pieces",
          value: "email_pieces",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Email Point",
          value: "email_point",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Incentive Value(s)",
          value: "incentive_value",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "First Name",
          value: "first_name",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Last name",
          value: "last_name",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Address",
          value: "address",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "City",
          value: "city",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "State",
          value: "state",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Zip",
          value: "postalcode",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Email",
          value: "email",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Date",
          value: 'dates',
          sortable: false,
          class: "v-data-table-header",
        },
      ],
      ////// table data ends here
      checkbox: false,
      affinity: [],
      checkbox1: false,
      dialog: false,
      dialog1: false,
      dialog2: false,
      categories: [],
      searchCategories: [],
      query: "",
      categ: "",
      category: "",
      deleteItems: [],
      checked_affinityid: [],
      checkcedstr: "",
      categor_count: [],
      product_data: [],
      productCount: [],
      affinityIds: [],
      message1: "",
      message2: "",
      upmessage1: "",
      upmessage2: "",
      allSelected: false,
      affinityNames: [],
      addAffinity: {
        affinityName: null,
        category: null,
        subCategory: null,
      },
      updateAffinity: {
        affinityId: null,
        affinityName: null,
        category: null,
        subCategory: null,
      },
      subCategory: [],
      subcatName: [],
      subcategories: [],
      checked: false,
      nameRules: [(v) => !!v || "Name is required"],
      //pagination code
      perpage: 50,
      totRecords: null,
      page: 1,
      isLoading: true,
      fullPage: true,
      showPerPage: false,
      date: new Date().toISOString().substr(0, 7),
      min:moment(new Date()).subtract(10, 'years').format("YYYY-MM-DD"),
      max:moment(new Date()).format("YYYY-MM-DD"),
      menu: false,
      modal: false,
      panelist_ids:"",
      exportData:[]
      ///
    };
  },
  computed: {
    //pagination computed methods
    startRecord: function () {
      return parseInt(this.lastRecord) - parseInt(this.perpage) + parseInt(1);
    },
    lastRecord: function () {
      return this.perpage * this.currentPage;
    },
    isCurrentPageClass: function () {
      return this.currentPage === 1 ? "" : "notactivespan";
    },
    getLastPageClass: function () {
      return parseInt(this.lastPage) === parseInt(this.currentPage)
        ? ""
        : "notactivespan";
    },
    totPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    getPages: function () {
      return this.$utils.getPages(
        this.lastPage,
        this.currentPage,
        this.totPage
      );
    },
    currentPage: function () {
      return this.page;
    },
    lastPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    endIndex: function(){
      if(this.page == 1){
       return this.perpage
      }else{
        return this.perpage * this.page
      }
    },
    startIndex: function(){
      if(this.page == 1){
       return this.perpage * 0
      }else{
        return this.endIndex - this.perpage
      }
    },
    fromDate: function(){
      let date  = this.date
      let dateArr = date.split('-')
      let month = dateArr[1]
      let year = dateArr[0]
      return year+"-"+month+"-01"
    },
    toDate: function(){
      let date  = moment(this.fromDate).add(1, 'M').format("YYYY-MM-DD")
      let dateArr = date.split('-')
      let month = dateArr[1]
      let year = dateArr[0]
      //let days = this.getMonthDays(month,year)
      return year+"-"+month+"-01"
    },
    panelist_ids_arr: function(){
      if(this.panelist_ids == ''){
        return []
      }else{
        let arr = this.panelist_ids.split(',')
        let new_arr = []
        arr.forEach((id)=>{
          new_arr.push(id.trim())
        })
        return new_arr
      }
    },
    csv_name: function(){
      return 'producer_scoring_monthly_report_'+this.date+'-'+moment(new Date()).format("DD")+'.csv'
    }
    ///////
  },
  methods: {
    //pagination methods
    exportReport(){
      let check = this;
      let producer_panelist_report_url = check.$url("DOWNLOAD_PANELIST_REPORT");
      check.isLoading = true;
      let data = {
          from_date : this.fromDate,
          to_date : this.toDate,
          type : "brok_panelist",
          panelist_list : this.panelist_ids_arr
      }
      this.$fetch({ requiresAuth: true, operation : 'export', vueScope: check })
        .post(producer_panelist_report_url, data)
        .then((res, status, xhr) => {
          check.isLoading = false;
          //this.exportData = JSON.parse(res.data)
          let temp_data = []
          let exportData = JSON.parse(res.data)
          exportData.forEach((d)=>{
            let obj = {
              "Panelist Id":d.competi_id,
              "Email Pieces": d.email_pieces,
              "Email Point": d.email_point,
              "Incentive Value": d.incentive_value,
              "First Name": d.first_name,
              "Last Name": d.last_name,
              "Address": d.address,
              "City": d.city,
              "State": d.state,
              "Zip": d.postalcode,
              "Email": d.email,
              "Date": this.date,
            }
            temp_data.push(obj)
          })
          this.exportData = temp_data
          //console.log(JSON.parse(res.data))
          console.log(this.exportData.length);
           setTimeout(function(){
              document.getElementById('exportButton').click()
            console.log(JSON.parse(res.data))
             }, 1000);

        })
        .catch((error) => {
          console.error(error);
        });
    },
    getMonthDays(month,year){
      if(month == '01'){
        return 31
      }else if(month == '02' && year % 4 == 0){
        return 29
      }else if(month == '02' && year % 4 != 0){
        return 28
      }else if(month == '03'){
        return 31
      }else if(month == '04'){
        return 30
      }else if(month == '05'){
        return 31
      }else if(month == '06'){
        return 30
      }else if(month == '07'){
        return 31
      }else if(month == '08'){
        return 31
      }else if(month == '09'){
        return 30
      }else if(month == '10'){
        return 31
      }else if(month == '11'){
        return 30
      }else if(month == '12'){
        return 31
      }
    },
    checkIfOperationExistForModule(type){
        return this.$utils.checkIfOperationExistForModule(this, type)
    },
    getDataByPage(value) {
      console.log(value);
      this.page = value;
      this.getReport(this.page);
    },
    handlePerPage(value) {
      console.log(value);
      this.perpage = value;
      this.getReport(this.page);
    },
    paginate(n) {
      console.log(n);
      this.page = n === 0 ? 1 : parseInt(this.currentPage) + parseInt(n);
      this.getReport(this.page);
    },
    last(value) {
      console.log(value);
      this.page = this.lastPage;
      this.getReport(this.page);
    },
    /////
    resetSearch(){
      if(this.query != null || this.categ!= ""){
        this.page = 1,
        this.query = "",
        this.categ = ""
        this.getReport();
      }
     },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.page = 1
      this.date = new Date().toISOString().substr(0, 7)
      this.panelist_ids = ''
      this.getReport()
    },
    getReport(page = "", query = "", categ = "") {
      let check = this;
      let producer_panelist_report_url = check.$url("PANELIST_REPORT");
      console.log(producer_panelist_report_url);
      check.isLoading = true;
      let data = {
          from_date : this.fromDate,
          to_date : this.toDate,
          type : "brok_panelist",
          start_index : this.startIndex,
          end_index : this.endIndex,
          panelist_list : this.panelist_ids_arr
      }
      this.$fetch({ requiresAuth: true, operation : 'view', vueScope: check })
        .post(producer_panelist_report_url, data)
        .then((res) => {
          check.isLoading = false;
          console.log(res.data);
          res.data.report = JSON.parse(res.data.report)
          if (res.data.report.length) {
            let result_data = []
            res.data.report.forEach(function (item) {
              result_data.push(item);
            });
            this.affinity_data = result_data
            this.totalAffinity = res.data.report_length
            this.totRecords = res.data.report_length;
            if(this.totRecords <= this.perpage){
              this.page = 1
            }
          }else{
            this.affinity_data = res.data.report
            this.totalAffinity = res.data.report_length
            this.totRecords = res.data.report_length;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    selectAll: function () {
      let delaffinity = this.affinity;
      if (this.allSelected == true) {
        if (Array.isArray(delaffinity) && delaffinity.length) {
          this.deleteItems = [];
          let i;
          for (i = 0; i < delaffinity.length; i++) {
            if (this.affinity_data[i].product_count == 0) {
              this.deleteItems.push(delaffinity[i][0]);
              console.log("gsdhghshsjss");
            }
          }
        }
      } else if (this.allSelected == false) {
        this.deleteItems = [];
      }
    },
    select: function () {
      this.allSelected = false;
    },
    submit() {
      this.$v.$touch();
    },
    searchAffinity: function () {
      this.getReport(this.page, this.query);
      console.log(this.query);
    },
  },
  watch: {
    '$store.state.Rawdata.current_active_module': function(o) {
        if(o){
          this.getReport(1);
        }
    },
    page: function (ob) {
        this.allSelected = false;
        this.deleteItems = [];
        //this.getReport(ob, this.query);
    },
    // exportData: function(data){
    //   if(data.length){
    //     document.getElementById('exportButton').click()
    //   }
    // }
  },
};
</script>
